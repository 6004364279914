
// Brands of Collection
export const BRAND_SAGA = "BRAND_SAGA";
export const BRAND_SUCCESS = "BRAND_SUCCESS";
export const BRAND_FAIL = "BRAND_FAIL";

// Brands of Collection
export const REGION_SAGA = "REGION_SAGA";
export const REGION_SUCCESS = "REGION_SUCCESS";
export const REGION_FAIL = "REGION_FAIL";

// List of Collections
export const COLLECTION_LIST_SAGA = "COLLECTION_LIST_SAGA";
export const COLLECTION_LIST_SUCCESS = "COLLECTION_LIST_SUCCESS";
export const COLLECTION_LIST_FAIL = "COLLECTION_LIST_FAIL";

// List of searched Collections
export const SEARCH_LIST_SAGA = "SEARCH_LIST_SAGA";
export const SEARCH_LIST_SUCCESS = "SEARCH_LIST_SUCCESS";
export const SEARCH_LIST_FAIL = "SEARCH_LIST_FAIL";