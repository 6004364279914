import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Loader from "./components/Loader/Loader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import WrongNetworkModal from "./components/WrongNetworkModal";
import { useEagerConnect, useInactiveListener } from "./hooks";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import {
  brandSaga,
  dummyAction,
  getFaqs,
  loginAction,
  loginActionSuccess,
  regionSaga,
  searchListSaga,
  setAuthentication,
} from "./store/actions";

const App = () => {
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    window.location.reload();
    deactivate();
    sessionStorage.setItem("connectedWallet", "");
    localStorage.clear();
    window.location.reload();
  };
  const handleShow = () => setShow(true);

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { active, chainId, account, error, deactivate } = useWeb3React();
  console.log("active", active, "account", account, token);

  useEffect(() => {
    if (account) {
      // dispatch(searchListSaga());
      dispatch(loginAction({ id: account }));
      dispatch(getFaqs());
      dispatch(regionSaga());
    }
  }, [account]);

  useEffect(() => {
    // dispatch(searchListSaga());
    dispatch(getFaqs());
    dispatch(brandSaga());
    dispatch(regionSaga());
  }, []);

  useEffect(() => {
    if (active || error) {
      if (chainId !== 137 || error) {
        console.log(error.message, error.response);
        sessionStorage.setItem("connectedWallet", "");
        localStorage.clear();
        // window.location.reload();
        console.log(error, active);
        if (error.message.includes("Unsupported chain")) setShow(true);
        else window.location.reload();
        // console.log("please switch to polygon testnet");
        deactivate();
      }
    }
  }, [error, chainId, active]);

  let mainContent = (
    <>
      {/* <Route
        exact
        path="/"
        component={React.lazy(() => import("./views/Dashboard/Dashboard"))}
      /> */}
      <Route
        exact
        path="/faqs"
        component={React.lazy(() => import("./views/FAQs/FAQs"))}
      />
      <Route
        exact
        path="/whitepaper"
        component={React.lazy(() => import("./views/WhitePaper/WhitePaper"))}
      />
      <Route
        exact
        path="/collections"
        component={React.lazy(() => import("./views/Collections/Collection"))}
      />
      <Route
        exact
        path="/landing"
        component={React.lazy(() => import("./views/Landing/Landing"))}
      />
      {/* {localStorage.getItem("userData") === null && <Redirect to="/" />} */}
    </>
  );

  if (active || localStorage.getItem("shouldEagerConnect")) {
    localStorage.setItem("shouldEagerConnect", true);

    mainContent = (
      <>
        <Route
          path="/"
          component={React.lazy(() =>
            import("./views/MainContainer/MainContainer")
          )}
        />
      </>
    );
  }

  const onChangeNetworkClick = async () => {
    // Metamask adds Ropsten chain by default, so no need to check wether chain is added or not
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x3" }],
    });
  };

  return (
    <>
      <Modal show={show} className="bg-black">
        <Modal.Body className="text-dark text-center bg-black">
          Please switch to polygon mainnet.
          <h3>{error?.message}</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="d-flex justify-content-center m-auto text-white bg-dark"
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <React.Suspense fallback={<Loader />}>
        {/* <WrongNetworkModal
        show={chainId !== 3 && active}
        onChangeNetworkClick={onChangeNetworkClick}
      /> */}

        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/"
              component={React.lazy(() =>
                import("./views/Dashboard/Dashboard")
              )}
            />
            {mainContent}
          </Switch>
        </BrowserRouter>
      </React.Suspense>
    </>
  );
};

export default App;
